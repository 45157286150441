@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;400;600&display=swap");

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent !important;
}

.text-abbreviated {
  white-space: no-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

html {
  --app-max-width: 40rem;
  --header-height: 3rem;

  /* https://flatuicolors.com/palette/gb */
  --color-primary: #487eb0;
  --font-large: 1.5rem;
  --unit: 1rem;

  font-family: Work Sans;
  background: rgb(255, 255, 255);
  /*
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(220, 221, 225, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  */
}

header {
  background: rgb(127, 143, 166);
  background: linear-gradient(
    188deg,
    rgba(127, 143, 166, 1) 0%,
    rgba(113, 128, 147, 1) 100%
  );
  color: #fff;
  display: flex;
  align-items: center;
  height: var(--header-height);
}

header h1 {
  font-weight: 400;
  letter-spacing: 0.2rem;
}

button,
a {
  appearance: none;
  border: none;
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: none;
}

h1,
h2,
button,
a {
  font-size: var(--font-large);
  font-family: Work Sans;
}

h1 {
  font-weight: 400;
}

.graphic {
  background-size: cover;
  background-repeat: no-repeat;
  max-width: var(--app-max-width);
}

.primary-column {
  padding-right: var(--unit);
  padding-left: var(--unit);
  max-width: var(--app-max-width);
}

.row {
  width: 100%;
  padding-bottom: var(--unit);
}

.primary {
  color: var(--color-primary);
}

.content-centered {
  margin-left: auto;
  margin-right: auto;
}

.centered {
  display: flex;
  justify-content: center;
  text-align: center;
}

.base-page-content {
  padding-top: var(--unit);
}

.fluid {
  width: 100%;
}

input {
  appearance: none;
  border: none;
  border: solid #ccc;
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem 0.25rem;
  border-radius: 0.2rem;
}

button,
a {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
